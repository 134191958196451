<template>
  <div class="columns is_centered">
    <div class="column">
      <div class="container">
        <div
          id="choice_input"
          class="block field has-addons"
          style="justify-content: center;"
        >
          <div class="control">
            <input
              v-model="text"
              type="text"
              class="input"
              placeholder="Enter text"
              @keyup.enter="addChoice"
            >
          </div>
          <div
            class="control"
            @click="addChoice"
          >
            <a
              id="add_button"
              class="button is-info"
            >
              Add
            </a>
          </div>
        </div>
        <div
          id="choice_list"
          class="block"
          style="margin-bottom: 2%;"
        >
          <table
            v-if="choices.length > 0"
            class="table is-striped is-hoverable mx-auto"
          >
            <tbody>
              <tr
                v-for="(choice, idx) in choices"
                :key="choice.id"
              >
                <td>{{ idx + 1 }}. {{ choice.text }}</td>
                <td>
                  <a @click="deleteChoice(choice.id)">
                    <span class="icon has-text-danger">
                      <i
                        id="trash"
                        class="fas fa-xmark"
                      />
                    </span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="choices.length > 1"
          class="block has-text-centered has-text-danger"
          style="margin-bottom: 2%;"
        >
          <p class="is-size-6" />
          <button
            class="button is-danger is-light is-small"
            @click="deleteAllChoices"
          >
            <i class="fa-solid fa-trash mr-1" />Remove all
          </button>
        </div>
        <div
          id="tip_for_adding"
          class="block has-text-centered"
        >
          <span
            class="tag is-dark mx-1"
            style="background-color: #cfcfcf;
          color: #626262; font-weight: 700; font-size: 55%;"
          >TIP</span>
          <span style="color: #686868;font-size: 65%;">You can also hit Enter to add a
            choice</span>
        </div>
        <div
          v-if="choices.length > 1"
          class="block has-text-centered"
        >
          <button
            id="pick_one_button"
            class="button is-medium is-success mx-1"
            @click="pickOne()"
          >
            Pick one
          </button>
          <button
            id="shuffle_button"
            class="button is-medium is-info mx-1"
            @click="shuffle()"
          >
            Shuffle <i class="fa-solid fa-shuffle ml-1" />
          </button>
        </div>
        <div
          v-if="showResultBox"
          ref="result_box"
          class="block"
        >
          <article class="message is-info">
            <div
              id="message_header"
              class="message-header"
            >
              <p>
                Result
              </p>
            </div>
            <div class="message-body">
              <div v-if="showResultBox && result">
                <p class="is-size-4">
                  <span style="white-space: pre;">{{ result }}</span>
                </p>
              </div>
              <div
                v-else
                style="text-align: center;"
              >
                <span class="loader" />
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function generateRandomInt(start, stop) {
  const range = stop - start;
  return Math.floor(start + Math.random() * range);
}

export default {
  name: 'App',
  components: {},
  data() {
    return {
      msg: 'Choices',
      text: '',
      choices: [
        // { id: 0, text: 'strawberry' },
        // { id: 1, text: 'raspberry' },
      ],
      showResultBox: false,
      isResultLoading: false,
      result: null,
    };
  },
  methods: {
    addChoice() {
      if (this.text.trim().length === 0) {
        return;
      }
      let maxID = 0;
      for (let i = 0; i < this.choices.length; i += 1) {
        const choiceID = this.choices[i].id;
        if (choiceID > maxID) {
          maxID = choiceID;
        }
      }

      this.choices.push({ id: maxID + 1, text: this.text });
      this.text = '';
      this.result = null;
      this.showResultBox = false;
    },
    deleteChoice(choiceID) {
      const newChoices = [];
      for (let i = 0; i < this.choices.length; i += 1) {
        const choice = this.choices[i];
        if (choice.id !== choiceID) {
          newChoices.push(choice);
        }
      }
      this.choices = newChoices;
      this.showResultBox = false;
    },
    deleteAllChoices() {
      this.choices = [];
      this.showResultBox = false;
    },
    async pickOne() {
      this.result = null;
      this.showResultBox = true;
      this.isResultLoading = true;
      setTimeout(() => {
        this.result = this.choices[Math.floor(Math.random() * this.choices.length)].text;
        this.isResultLoading = false;
      }, generateRandomInt(250, 550));
      this.scrollToBottom();
    },
    async shuffle() {
      this.result = null;
      this.showResultBox = true;
      this.isResultLoading = true;
      setTimeout(() => {
        // Fisher-Yates shuffle
        let result = '';
        const newList = this.choices.slice();
        for (let i = newList.length - 1; i > 0; i -= 1) {
          const j = Math.floor(Math.random() * (i + 1));
          [newList[i], newList[j]] = [newList[j], newList[i]];
        }

        // Create the string for the shuffled list
        for (let i = 0; i < newList.length; i += 1) {
          result += `${i + 1}. ${newList[i].text}\n`;
        }
        this.result = result;
        this.isResultLoading = false;
      }, generateRandomInt(250, 550));
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$refs.result_box?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
  },
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  font-size: large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#trash:hover {
  color: #c30000;
}

#add_button {
  background-color: #306754;
}

#add_button:hover {
  background-color: #275644;
}

#pick_one_button {
  background-color: #F05A7E;
}

#pick_one_button:hover {
  background-color: #d75171;
}

#shuffle_button {
  background-color: #125B9A;
}

#shuffle_button:hover {
  background-color: #0f4a7e;
}

#message_header {
  background-color: #0B8494;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #3e8ed0;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
